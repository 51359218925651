(function(){
'use strict';

frsFundraiserRoutesConfig.$inject = ["$stateProvider", "scConstants"];
function frsFundraiserRoutesConfig($stateProvider, scConstants) {
  $stateProvider.state('frs.fundraiser', {
    url: '/fundraiser/:fundraisingPageId?preview=true&campaign-templating-view&is_new&campaignId&organizationId&published&register&' + scConstants.urlParams.utmCodes,
    abstract: true,
    templateUrl: 'frs/shared/views',
    controller: 'fundraiserCtrl'
  }).state('frs.fundraiser.index', {
    url: '',
    views: {
      'body@frs.fundraiser': {
        templateUrl: 'frs/shared/blocks'
      }
    },
    metaTags: {
      title: ['scFundraisingPagesService', 'scOrganizationsService', function (scFundraisingPagesService, scOrganizationsService) {
        var fundraiser = _.get(scFundraisingPagesService, 'active.saved', false);

        var text = fundraiser.alias ? fundraiser.alias + '\'s fundraising page for ' : 'Fundraising for ';
        text += scOrganizationsService.active.current.name;

        return text;
      }],
      description: ['scMetaTags', function (scMetaTags) {
        return scMetaTags.getEntityDescription('page');
      }],
      properties: {
        'og:image': ['scBrandingService', function (scBrandingService) {
          var fundraiserImage = scBrandingService.getShareImage('fundraiser');
          return fundraiserImage;
        }],

        'twitter:image': ['scBrandingService', function (scBrandingService) {
          var fundraiserImage = scBrandingService.getShareImage('fundraiser');
          return fundraiserImage;
        }],

        'twitter:title': ['scFundraisingPagesService', 'scOrganizationsService', function (scFundraisingPagesService, scOrganizationsService) {
          var fundraiser = _.get(scFundraisingPagesService, 'active.saved', false);

          var text = fundraiser.alias ? fundraiser.alias + '\'s fundraising page for ' : 'Fundraising for ';
          text += scOrganizationsService.active.current.name;

          return text;
        }],

        'og:description': ['scMetaTags', function (scMetaTags) {
          return scMetaTags.getEntityDescription('page');
        }],

        'twitter:description': ['scMetaTags', function (scMetaTags) {
          return scMetaTags.getEntityDescription('page');
        }]
      }
    }
  })
  /**
   * Create fundraising page
   */
  .state('frs.fundraiser.index.create-fundraising-page', {
    url: '/fundraiser/create?' + scConstants.urlParams.utmCodes,
    views: {
      'body@frs.fundraiser': {
        templateUrl: 'frs/shared/blocks',
        controller: 'frs-createFundraisingPageCtrl'
      }
    },
    metaTags: {
      title: 'Create a Fundraising Page'
    },
    resolve: {
      resolvedUserFundraisers: ['$http', 'scMembersService', 'scCampaignsService', function ($http, scMembersService, scCampaignsService) {
        if (_.get(scMembersService, 'active.current.id')) {
          return $http({
            method: 'GET',
            url: '/frs-api/campaign/' + scCampaignsService.active.current.id + '/fundraising-pages',
            params: {
              filter: 'member_id=' + scMembersService.active.current.id,
              status: 'active',
              per_page: 5,
              aggregates: true
            }
          });
        }
        return null;
      }]
    }
  })
  /**
   * Post My Story Update
   */
  .state('frs.fundraiser.index.post', {
    url: '/updates/:postId?' + scConstants.urlParams.utmCodes,
    resolve: {
      postData: ['$http', '$stateParams', function ($http, $stateParams) {
        return $http({
          method: 'GET',
          url: '/frs-api/updates/' + $stateParams.postId
        });
      }]
    },
    metaTags: {
      title: ['postData', function (postData) {
        var pageTitle = _.get(postData, 'data.title', 'Fundraiser Update');
        return $(pageTitle).text();
      }]
    }
  });
}

angular.module('classy.frs').config(frsFundraiserRoutesConfig);
})();