(function(){
'use strict';

frsTeamRoutes.$inject = ["$stateProvider", "scConstants"];
function frsTeamRoutes($stateProvider, scConstants) {
  $stateProvider.state('frs.team', {
    url: '/team/:fundraisingTeamId?preview=true&campaign-templating-view&campaignId&organizationId&published&is_new&' + scConstants.urlParams.utmCodes,
    abstract: true,
    templateUrl: 'frs/shared/views',
    controller: 'teamCtrl'
  }).state('frs.team.index', {
    url: '',
    views: {
      'body@frs.team': {
        templateUrl: 'frs/shared/blocks'
      }
    },
    metaTags: {
      title: ['scFundraisingTeamsService', 'scOrganizationsService', function (scFundraisingTeamsService, scOrganizationsService) {
        var teamName = _.get(scFundraisingTeamsService, 'active.saved.name', false);

        var teamEndsWithS = teamName ? teamName.toLowerCase().slice(-1) === 's' : false;
        var possessiveChar = teamEndsWithS ? "'" : "'s";

        return 'Check out ' + teamName + possessiveChar + ' team fundraising page for ' + scOrganizationsService.active.current.name;
      }],
      description: ['scMetaTags', function (scMetaTags) {
        return scMetaTags.getEntityDescription('team');
      }],
      properties: {
        'og:image': ['scBrandingService', function (scBrandingService) {
          var teamImage = scBrandingService.getShareImage('team');
          return teamImage;
        }],

        'og:title': ['scFundraisingTeamsService', 'scOrganizationsService', function (scFundraisingTeamsService, scOrganizationsService) {
          var teamName = _.get(scFundraisingTeamsService, 'active.saved.name', false);
          var teamEndsWithS = teamName ? teamName.toLowerCase().slice(-1) === 's' : false;
          var possessiveChar = teamEndsWithS ? "'" : "'s";

          return 'Check out ' + teamName + possessiveChar + ' team fundraising page for ' + scOrganizationsService.active.current.name;
        }],

        'twitter:image': ['scBrandingService', function (scBrandingService) {
          var teamImage = scBrandingService.getShareImage('team');
          return teamImage;
        }],

        'twitter:title': ['scFundraisingTeamsService', 'scOrganizationsService', function (scFundraisingTeamsService, scOrganizationsService) {
          var teamName = _.get(scFundraisingTeamsService, 'active.saved.name', false);
          var teamEndsWithS = teamName ? teamName.toLowerCase().slice(-1) === 's' : false;
          var possessiveChar = teamEndsWithS ? "'" : "'s";

          return 'Check out ' + teamName + possessiveChar + ' team fundraising page for ' + scOrganizationsService.active.current.name;
        }],
        'twitter:description': ['scMetaTags', function (scMetaTags) {
          return scMetaTags.getEntityDescription('team');
        }],
        'og:description': ['scMetaTags', function (scMetaTags) {
          return scMetaTags.getEntityDescription('team');
        }]
      }
    }
  })
  /**
   * Create fundraising page
   */
  .state('frs.team.index.create-fundraising-page', {
    url: '/fundraiser/create',
    views: {
      'body@frs.team': {
        templateUrl: 'frs/shared/blocks',
        controller: 'frs-createFundraisingPageCtrl'
      }
    },
    metaTags: {
      title: 'Create a Fundraising Page'
    },
    resolve: {
      resolvedUserFundraisers: ['$http', 'scMembersService', 'scCampaignsService', function ($http, scMembersService, scCampaignsService) {
        if (_.get(scMembersService, 'active.current.id')) {
          return $http({
            method: 'GET',
            url: '/frs-api/campaign/' + scCampaignsService.active.current.id + '/fundraising-pages',
            params: {
              filter: 'member_id=' + scMembersService.active.current.id,
              per_page: 5,
              aggregates: true,
              status: 'active'
            }
          });
        }
        return null;
      }]
    }
  })
  /**
   * Post My Story Update
   */
  .state('frs.team.index.post', {
    url: '/updates/:postId?' + scConstants.urlParams.utmCodes,
    resolve: {
      postData: ['$http', '$stateParams', function ($http, $stateParams) {
        return $http({
          method: 'GET',
          url: '/frs-api/posts/' + $stateParams.postId
        });
      }]
    },
    metaTags: {
      title: ['postData', function (postData) {
        var pageTitle = _.get(postData, 'data.title', 'Team Update');
        return $(pageTitle).text();
      }]
    }
  });
}

angular.module('classy.frs').config(frsTeamRoutes);
})();