(function(){
'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

angular.module('classy.frs').component('registrationPayment', {
  template: '\n      <checkout-section>\n        <cpt-headline>Checkout</cpt-headline>\n        <cpt-body>\n          <ng-include src="$ctrl.tpl(\'payment\')" ng-if="$ctrl.state === \'ready\'"></ng-include>\n          <ng-include src="$ctrl.tpl(\'processing\')" ng-if="$ctrl.state === \'processing\'"></ng-include>\n          <ng-include src="$ctrl.tpl(\'confirmed\')" ng-if="$ctrl.state === \'confirmed\'"></ng-include>\n        </cpt-body>\n      </checkout-section>\n    ',
  controller: ["$rootScope", "$scope", "$timeout", "$q", "US_STATE_LIST", "CA_PROVINCE_LIST", "CPPaymentModel", "registrationNavigator", "registrationRoutes", "scBanner", "scCampaignsService", "scOrganizationsService", "scCartService", "scMembersService", "scThemesService", "scValidityService", "scFlowModalService", "countryBlockService", "cpConstants", "$window", function controller($rootScope, $scope, $timeout, $q, US_STATE_LIST, CA_PROVINCE_LIST, CPPaymentModel, registrationNavigator, registrationRoutes, scBanner, scCampaignsService, scOrganizationsService, scCartService, scMembersService, scThemesService, scValidityService, scFlowModalService, countryBlockService, cpConstants, $window) {
    var _this2 = this;

    var IS_CANADIAN = scOrganizationsService.active.current.country === 'CA' || scOrganizationsService.active.current.country === 'CAN';

    var PAYMENT_DEFAULTS = {
      country: 'US',
      method: 'CC'
    };

    var PAYMENT_ROUTE = registrationRoutes.PAYMENT_ROUTE,
        RECEIPT_ROUTE = registrationRoutes.RECEIPT_ROUTE,
        TICKETS_ROUTE = registrationRoutes.TICKETS_ROUTE;

    /* -------------------------------------------------------------------- *
     * Life cycle
     * -------------------------------------------------------------------- */

    this.$onInit = function () {
      var _this = this;

      this.state = 'ready';
      this.paymentModel = this.setupPaymentModel();
      this.disabledPaymentMethods = ['ACH']; // ACH not supported for registrations.
      this.api = {
        recalculateFees: function recalculateFees(paymentMethod, processorName) {
          var isAmex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

          scCartService.active.setFeeParams({ paymentMethod: paymentMethod, processorName: processorName, isAmex: isAmex });
        }
      };
      this.formName = 'paymentForm';
      this.isDimmed = false;
      this.deregisterBeforeChange = this.onBeforeChange();
      this.deregisterChange = this.onChange();
      this.validityModel = scValidityService.get('registration').clear();
      this.primaryColor = scThemesService.active.current.styles.primaryColor;
      this.fullBillingAddress = scCampaignsService.active.current.is_billing_address_required;
      this.usStateList = _.cloneDeep(US_STATE_LIST);
      this.caProvinceList = _.cloneDeep(CA_PROVINCE_LIST);
      this.watchCountry();
      this.paymentModel.country = IS_CANADIAN ? 'CA' : 'US';
      this.hideOptIn = scCampaignsService.active.current.hide_contact_opt_in;
      this.showCommitmentWaiver = scCartService.active.stagedItems.some(function (si) {
        return !!si.commitment;
      }) && _.get(scCampaignsService, 'active.current.type') === 'reg_w_fund';
      this.commitmentWaiverModel = false;
      this.nameOfOrganization = scOrganizationsService.active.current.name;
      this.nameOfCampaign = scCampaignsService.active.current.name;

      this.showOptIntoPaymentSync = !!scCampaignsService.active.current.classy_live_event_id;

      countryBlockService.getCountryBlockedList().then(function (response) {
        _this.countryList = response;
      });

      $rootScope.$on('cart:setIsAmex', function (_event, isAmex) {
        scCartService.active.setFeeParams({ isAmex: isAmex });
      });
    };

    this.togglePurchaserWatch = $scope.$watchGroup(['paymentModel.firstName', 'paymentModel.lastName'], function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          first = _ref2[0],
          last = _ref2[1];

      _this2.paymentModel.billingFirstName = first;
      _this2.paymentModel.billingLastName = last;
    });

    this.$onDestroy = function () {
      this.deregisterBeforeChange();
      this.deregisterChange();
    };

    /* -------------------------------------------------------------------- *
     * Form
     * -------------------------------------------------------------------- */

    this.submitForm = function () {
      var reporter = this.validityModel.reporters[this.formName];
      return reporter.valid && registrationNavigator.next();
    };

    /* -------------------------------------------------------------------- *
     * Navigation
     * -------------------------------------------------------------------- */

    this.onBeforeChange = function () {
      var _this3 = this;

      return registrationNavigator.on('beforeChange',
      // eslint-disable-next-line no-unused-vars
      function (fromRoute, fromParams, toRoute, toParams) {
        if (fromRoute === PAYMENT_ROUTE && toRoute === RECEIPT_ROUTE) {
          return _this3.submit();
        }

        return undefined;
      });
    };

    /* -------------------------------------------------------------------- *
     * Nav interceptors
     * -------------------------------------------------------------------- */

    this.onChange = function () {
      return registrationNavigator.on('change', function (fromRoute, _fromParams, toRoute) {
        if (fromRoute === PAYMENT_ROUTE && toRoute === TICKETS_ROUTE && scCartService.active.isReserved && !scCampaignsService.active.HAS_FUNDRAISING) {
          registrationNavigator.confirmExit(function (reroute) {
            if (reroute) {
              scCartService.create().then(function () {
                scFlowModalService.close();
                registrationNavigator.go(TICKETS_ROUTE);
              });
            }
          });
          return false;
        }
        return undefined;
      });
    };

    /* -------------------------------------------------------------------- *
     * Templates
     * -------------------------------------------------------------------- */

    this.tpl = function (slug) {
      return 'frs/registration/components/payment/inc/' + slug;
    };

    this.addAddress2 = function () {
      this.showAddress2 = true;
    };

    this.removeAddress2 = function () {
      this.paymentModel.address2 = '';
      this.showAddress2 = false;
    };

    /* -------------------------------------------------------------------- *
     * Other
     * -------------------------------------------------------------------- */

    this.setupPaymentModel = function () {
      var paymentData = _.merge({}, PAYMENT_DEFAULTS, {
        firstName: _.get(scMembersService, 'active.current.first_name', ''),
        lastName: _.get(scMembersService, 'active.current.last_name', ''),
        email: _.get(scMembersService, 'active.current.email_address', ''),
        billingFirstName: _.get(scMembersService, 'active.current.first_name', ''),
        billingLastName: _.get(scMembersService, 'active.current.last_name', ''),
        currency: _.get(scOrganizationsService, 'active.current.currency', 'USD'),
        opt_in: _.get(scCampaignsService, 'active.current.opt_in_checked_by_default'),
        opt_in_wording: _.get(scOrganizationsService, 'active.current.opt_in_wording'),
        registrant_opted_into_payment_sync: true,
        cc: {},
        paypal: {},
        stripe: {},
        ach: {}
      });
      return new CPPaymentModel(paymentData);
    };

    this.isFreeTransaction = function () {
      return !scCartService.active.total;
    };

    // We still show the payment widget on free transactions if the tickets have a commitment on it
    this.paymentRequired = function () {
      // not free so payment it required
      if (!_this2.isFreeTransaction()) {
        return true;
      }

      // it's free but it's not a commitment ticket so payment is not required
      if (!_this2.showCommitmentWaiver) {
        return false;
      }

      // it's a free transaction and it's also a commitment ticket, check if there is an attendee that has matching billing email
      return scCartService.getAttendees().some(function (a) {
        return a.email_address.toLowerCase() === _this2.paymentModel.email.toLowerCase();
      });
    };

    this.watchCountry = function () {
      var _this4 = this;

      $scope.$watch('$ctrl.paymentModel.country', function () {
        _this4.paymentModel.state = '';
      });
    };

    this.postalCodeErrorMessages = {
      minlength: 'Please enter at least 2 characters.',
      maxlength: 'Please enter no more than 10 characters.'
    };

    this.getFrMinimumInfoDate = function () {
      var firstCommitmentDeadline = scCartService.getFrMinimumInfo().firstDue.commitment.deadline;
      return moment.utc(firstCommitmentDeadline).local().format('MMMM Do YYYY, h:mm a') + ' ' + moment().tz(scCampaignsService.active.current.timezone_identifier).format('z');
    };

    /* -------------------------------------------------------------------- *
     * Form submit
     * -------------------------------------------------------------------- */

    this.scrollToElement = function (inputElement) {
      var isMobile = void 0;
      if (Modernizr.prefixed('matchMedia', window)) {
        isMobile = window.matchMedia('(max-width: 1024px)').matches;
      } else {
        isMobile = angular.element($window).width() <= 1025 && angular.element($window).width() >= 768;
      }
      $timeout(function () {
        var scrollSelector = isMobile ? 'html, body' : '.checkout-section__body';
        $('' + scrollSelector).animate({
          scrollTop: inputElement.offset().top
        }, 400);
      });
    };

    this.submit = function () {
      var _this5 = this;

      if (this.paymentModel.ach.status === cpConstants.STATUS.SUCCESS && !this.paymentModel.ach.achTermsAccepted) {
        scBanner('Please agree to the terms and conditions.');
        this.scrollToElement(angular.element('[name="achTerms"]'));
        return undefined;
      }
      if (scCampaignsService.active.current.status !== 'active') {
        scBanner('Whoops, this is just a preview. Please publish your campaign first.');
        return undefined;
      }

      var setupPayment = this.paymentRequired() ? this.api.setModelPayment : $q.resolve;
      var transactionTotal = scCartService.active.total;

      return setupPayment().then(function () {
        _this5.state = 'processing';
        return scCartService.active.submit(_this5.paymentModel);
      }).then(function () {
        _this5.state = 'confirmed';
        return $timeout(1500);
      }).then(function () {
        return {
          route: RECEIPT_ROUTE,
          params: {
            verify: scCartService.active.metadata.verify,
            total: transactionTotal
          }
        };
      }).catch(function () {
        $rootScope.$broadcast('tokenizer', 'reset');
        _this5.state = 'ready';
      });
    };
  }]
});
})();