(function(){
'use strict';

teamCtrl.$inject = ["$scope", "$stateParams", "scFundraisingTeamsService", "scAppealSetService", "scAnalytics"];
function teamCtrl($scope, $stateParams, scFundraisingTeamsService, scAppealSetService, scAnalytics) {
  $scope.page = $scope.theme.pages.team;
  if ($scope.previewMode) {
    $scope.team = scFundraisingTeamsService.useExample();
  } else {
    $scope.team = scFundraisingTeamsService.active;

    scAnalytics.track('supporter_loaded', { team: $scope.team.current });
  }

  if ($stateParams.is_new) {
    scAppealSetService.rescrape('team', true);
  }
}

angular.module('classy.frs').controller('teamCtrl', teamCtrl);
})();