(function(){
'use strict';

startFundraisingCtrl.$inject = ["$scope", "fundraiseSwitchFlow", "registrationEntityFlow", "scOrganizationsService", "scCampaignsService", "scThemesService"];
function startFundraisingCtrl($scope, fundraiseSwitchFlow, registrationEntityFlow, scOrganizationsService, scCampaignsService, scThemesService) {
  // if we are loading this controller clear out any previous selection of teams for joining.
  // this prevents us from attempting to attribute a previously chosen team to a brand new cart.
  window.localStorage.removeItem('reg-cart-team');

  // Configure block rendering
  $scope.public = true;
  $scope.editable = false;
  $scope.demo = false;
  $scope.organization = scOrganizationsService.active.current;
  $scope.campaign = scCampaignsService.active.current;
  $scope.theme = scThemesService.active.current;
  $scope.page = $scope.theme.pages.landing;

  // Launch flow without animation since it's right when the page loads
  if ($scope.campaign.type == 'reg_w_fund') {
    registrationEntityFlow();
  } else {
    fundraiseSwitchFlow({ animate: false });
  }
}

angular.module('classy.frs').controller('startFundraisingCtrl', startFundraisingCtrl);
})();