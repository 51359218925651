(function(){
'use strict';

frsUnsubscribeController.$inject = ["$http", "$scope", "$stateParams", "scBlocksService", "scCampaignsService", "scThemesService", "scBanner"];
function frsUnsubscribeController($http, $scope, $stateParams, scBlocksService, scCampaignsService, scThemesService, scBanner) {
  $scope.email_address = $stateParams.email;
  $scope.recipient = $stateParams.recipient;

  $scope.campaign_name = scCampaignsService.active.current.name;

  $scope.theme = scThemesService.active.current;

  var defaultSubscribeOptions = {
    feed_item_comment: false,
    post_create: false,
    marketing: false,
    opt_in: false
  };

  $scope.subscribeOptions = defaultSubscribeOptions;

  $scope.campaignSubscribeOptions = [{
    label: 'Comments on donor activity',
    model: 'subscribeOptions.feed_item_comment',
    fieldName: 'pageComments'
  }, {
    label: 'Updates posted to the campaign',
    model: 'subscribeOptions.post_create',
    fieldName: 'pageDonations'
  }];

  $scope.set = {
    preferences: function preferences() {
      var preferences = _.filter(_.map($scope.subscribeOptions, function (value, key) {
        if (!value) {
          return key;
        }

        return undefined;
      }), function (pref) {
        return pref;
      });

      var data = { unsubscribe_tags: preferences };

      $http.post('/frs-api/notification-preferences/' + $stateParams.recipient, data).then(function () {
        scBanner('Your notification preferences have been updated', 'neutral');
      }, function () {
        scBanner('There was a problem updating your preferences, check your email link.', 'error');
      });
    },
    initialOptions: function initialOptions(response) {
      var rawTags = response.data.unsubscribe_tags;
      // var currentOptions = _.clone($scope.subscribeOptions);

      var truthy = {
        feed_item_comment: true,
        post_create: true,
        marketing: true,
        opt_in: true
      };

      _.each(rawTags, function (tag) {
        truthy[tag] = false;
      });

      $scope.subscribeOptions = truthy;
    }
  };

  $scope.get = {
    block: function block(type) {
      return scBlocksService.getIdByType(type);
    },
    currentOptions: function currentOptions() {
      $http.get('/frs-api/notification-preferences/' + $stateParams.recipient).then(function (response) {
        $scope.set.initialOptions(response);
      }, function () {
        scBanner('There was a problem finding your preferences, check your email link.', 'error');
      });
    },
    submitButtonStyle: function submitButtonStyle() {
      var headerBlock = scBlocksService.getBlockByType('header').current;
      return {
        backgroundColor: $scope.theme.styles.primaryColor,
        color: headerBlock.background.color,
        borderColor: $scope.theme.styles.primaryColor
      };
    }
  };

  $scope.get.currentOptions();
}

angular.module('classy.frs').controller('frs-unsubscribeCtrl', frsUnsubscribeController);
})();