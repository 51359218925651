(function(){
'use strict';

fundraiserCtrl.$inject = ["$scope", "$state", "scFundraisingPagesService", "fundraiseSwitchFlow", "scAppealSetService", "scAnalytics"];
function fundraiserCtrl($scope, $state, scFundraisingPagesService, fundraiseSwitchFlow, scAppealSetService, scAnalytics) {
  $scope.page = $scope.theme.pages.fundraiser;

  if ($scope.previewMode) {
    $scope.fundraiser = scFundraisingPagesService.useExample();
  } else {
    $scope.fundraiser = scFundraisingPagesService.active;

    scAnalytics.track('supporter_loaded', { fundraiser: $scope.fundraiser.current });
  }

  if ($state.params.is_new) {
    scAppealSetService.rescrape('fundraiser', true);
  }

  if ($state.params.register) {
    fundraiseSwitchFlow({
      disableIndividual: true,
      fundraiser: $scope.fundraiser.current.id
    });
  }
}

angular.module('classy.frs').controller('fundraiserCtrl', fundraiserCtrl);
})();